import React from 'react';
import {Header as ThemeHeader} from '@nib/gatsby-theme-mesh-docs';
import {ChatSystemIcon, ComputerSystemIcon, EditSystemIcon, UsageSystemIcon} from '@nib/icons';

const navLinks = [
  {
    icon: EditSystemIcon,
    title: 'Design',
    url: '/getting-started/design/'
  },
  {
    icon: ComputerSystemIcon,
    title: 'Develop',
    url: '/getting-started/develop/'
  },
  {
    icon: UsageSystemIcon,
    title: 'Analytics',
    url: '/analytics/'
  },
  {
    icon: ChatSystemIcon,
    title: 'Feedback',
    url: '/feedback/'
  }
];

const Header = props => <ThemeHeader {...props} navLinks={navLinks} />;

export default Header;
