'use client';
import React from 'react';
import BaseIcon from '../../utils/BaseIcon';

const ComputerSystemIcon = props => {
  const {size = 24, ...otherProps} = props;
  const iconFill = 'currentColor';

  return (
    <BaseIcon size={size} {...otherProps} data-mesh-component= 'COMPUTER-SYSTEM-ICON'>
      <g fill="none" fillRule="evenodd"><path d="M0 0h24v24H0z"/><path fill={iconFill} fillRule="nonzero" d="M20.159.582a3.75 3.75 0 0 1 3.75 3.75v11.136a3.75 3.75 0 0 1-3.75 3.75l-4.29-.001.482 2.785h1.602a.75.75 0 0 1 .743.648l.007.102a.75.75 0 0 1-.75.75h-11.9a.75.75 0 0 1 0-1.5h1.6l.483-2.785h-4.29a3.75 3.75 0 0 1-3.75-3.75V4.333a3.75 3.75 0 0 1 3.75-3.75H20.16m-5.812 18.636H9.659l-.484 2.784h5.655l-.483-2.784m7.877-2.855H1.78a2.25 2.25 0 0 0 2.065 1.355H20.16a2.25 2.25 0 0 0 2.065-1.355M20.159 2.082H3.846a2.25 2.25 0 0 0-2.25 2.25v10.53h20.812V4.332a2.25 2.25 0 0 0-2.25-2.25Z"/></g>
    </BaseIcon>
  );
};

ComputerSystemIcon.displayName = 'ComputerSystemIcon';
ComputerSystemIcon.iconName = 'Computer';

export default ComputerSystemIcon;
